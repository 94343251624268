export const JWT_DATA_COOKIE_KEY = 'jwt-data'
export const JWT_SIGNATURE_COOKIE_KEY = 'jwt-signature'
export const JWT_REFRESH_TOKEN_COOKIE_KEY = 'jwt-refresh-token'
export const JWT_TOKEN_COOKIE_KEY = 'jwt_token'

// luxola specific cookie keys
export const LUXOLA_SESSION_COOKIE_KEY = '_luxola_session'
export const SIGNED_IN_COOKIE_KEY = 'signed_in'
export const USER_PAYLOAD_COOKIE_KEY = 'user_payload'
export const USER_BUCKET_ID_COOKIE_KEY = 'user-bucket-id'

// For Google one tap use. this key is shared with luxola & web-ui
export const GOOGLE_ONE_TAP_SESSION_STORAGE_KEY =
  'google-one-tap-blocked-details'

export const LOGIN_STATUS_USER_SEGMENT_GROUP = 'Login Status'
export const GUEST_USER_SEGMENT = 'Guest'
export const SIGNED_IN_USER_SEGMENT = 'Signed In'
